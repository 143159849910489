class ScopeService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getScopes() {
    const request = {
      method: 'GET',
      url: '/api/scopes',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async postScopes(scope) {
    const request = {
      method: 'POST',
      url: '/api/scopes',
      data: scope,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async deleteScopes(scopeId) {
    const request = {
      method: 'DELETE',
      url: `/api/scopes/${scopeId}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async patchScopes(scopeId, scope) {
    const request = {
      method: 'PATCH',
      url: `/api/scopes/${scopeId}`,
      data: scope,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }
}

export default ScopeService;
