import VueI18n from '../plugins/vue-i18n';

import { roleService, authService } from '../services';

export default {
  state: {
    roles: [],
  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    ADD_ROLE(state, role) {
      state.roles = [...state.roles, role];
    },
    PATCH_ROLE(state, role) {
      state.roles = [...state.roles.filter((_role) => _role._id !== role._id), role];
    },
    DELETE_ROLE(state, roleId) {
      state.roles = state.roles.filter((_role) => _role._id !== roleId);
    },
  },
  actions: {
    async getRoles(context) {
      if (authService.hasPermission('ROLES_READ')) {
        try {
          const response = await roleService.getRoles();
          context.commit('SET_ROLES', response);
        } catch (error) {
          context.commit('ADD_ERROR', VueI18n.t('Errors.Roles.get'));
          // throw error;
        }
      }
    },
  },
};
