import tokenStorageService from '@/services/TokenStorageService';
import AuthService from '@/services/AuthService';
import ApiClient from '@/services/apiService';
import UserService from '@/services/UserService';
import LeaseService from '@/services/LeaseService';
import RoleService from '@/services/RoleService';
import EntityService from '@/services/EntityService';
import ScopeService from '@/services/ScopeService';
import SupportService from '@/services/SupportService';
import UploadService from '@/services/UploadService';
import InseeService from '@/services/InseeService';

import config from '@/config';

const authService = new AuthService({ config: config.api.backend, tokenStorageService });
const apiClient = new ApiClient({ config: config.api.backend, authService, tokenStorageService });
const uploadService = new UploadService({ config: config.api.upload });
const leaseService = new LeaseService({ apiClient, uploadService });
const userService = new UserService({ apiClient });
const roleService = new RoleService({ apiClient });
const entityService = new EntityService({ apiClient });
const scopeService = new ScopeService({ apiClient });
const supportService = new SupportService({ apiClient });
const inseeService = new InseeService({ config: config.api.insee });

export {
  tokenStorageService,
  userService,
  leaseService,
  roleService,
  authService,
  entityService,
  scopeService,
  supportService,
  uploadService,
  inseeService,
};
