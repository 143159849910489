import VueI18n from '../plugins/vue-i18n';

import { leaseService, authService } from '../services';

export default {
  state: {
    leases: [],
    resumeFields: [
      /* adress */
      {
        _id: 'adress', type: 'string', label: 'Adresse', category: 'adress', required: true, order: 0,
      },
      {
        _id: 'zipCode', type: 'string', label: 'Code postal', category: 'adress', required: true, order: 1,
      },
      {
        _id: 'town', type: 'string', label: 'Ville', category: 'adress', required: true, order: 2,
      },
      {
        _id: 'country', type: 'string', label: 'Pays', category: 'adress', required: true, order: 3,
      },
      {
        _id: 'scope', type: 'list', label: 'Secteur', category: 'adress', populate: true, required: true, order: 4,
      },
      /* date */
      {
        _id: 'leaseStart', type: 'date', label: "Date de prise d'effet", category: 'date', required: true, order: 0,
      },
      {
        _id: 'leaseDuration', type: 'period', label: 'Durée du bail', category: 'date', required: true, order: 1,
      },
      {
        _id: 'leaseEnd', type: 'date', label: 'Date de fin de bail', category: 'date', required: true, order: 2,
      },
      /* rent */
      {
        _id: 'rentGlobal',
        type: 'currency',
        label: 'Loyer global annuel',
        tooltip: 'Ce montant correspond aux dernières valeurs de loyer, charges et taxes enregistrées dans la plateforme',
        category: 'rent',
        calculate: true,
        order: 0,
      },
      /* status */
      {
        _id: 'status', type: 'list', label: 'Actif / Inactif', category: 'status', required: true, order: 0,
      },
      {
        _id: 'statusDate', type: 'date', label: "Date d'inactivité", category: 'status', order: 1,
      },
    ],
    infoFields: [
      /* info */
      {
        _id: 'entity', type: 'list', label: 'Locataire', category: 'info', populate: true, required: true, order: 0,
      },
      {
        _id: 'lessor', type: 'string', label: 'Bailleur', category: 'info', required: true, order: 1,
      },
      {
        _id: 'leaseType', type: 'list', label: "Type d'occupation", category: 'info', required: true, order: 2,
      },
      /* detail */
      {
        _id: 'area', type: 'surface', label: 'Bureau ou commerce', category: 'detail', required: true, order: 0,
      },
      {
        _id: 'activityArea', type: 'surface', label: 'Activité', category: 'detail', order: 1,
      },
      {
        _id: 'otherArea',
        type: 'surface',
        label: 'Divers',
        category: 'detail',
        tooltip: 'Cette surface n’est pas comptabilisée dans le calcul de la surface totale',
        optional: true,
        order: 2,
      },
      {
        _id: 'parking', type: 'number', label: 'Nombre de parking', category: 'detail', order: 3,
      },
      /* date */
      {
        _id: 'dateRelease', type: 'dateRelease', label: 'Échéances', category: 'leave', required: true, history: true, order: 0,
      },
      {
        _id: 'dateDenunciation',
        type: 'date',
        label: 'Prochaines dates de dénonciations',
        category: 'leave',
        history: true,
        calculate: true,
        order: 1,
      },
      {
        _id: 'dateAlert',
        type: 'date',
        label: "Prochaines dates d'alertes",
        tooltip: 'Date à laquelle vous serez prévenu de l’approche de la prochaine échéance',
        category: 'leave',
        history: true,
        calculate: true,
        order: 2,
      },
      /* sign */
      {
        _id: 'signRent', type: 'currency', label: 'Loyer bureau annuel HT', category: 'sign_in', order: 0,
      },
      {
        _id: 'signActivities', type: 'currency', label: 'Loyer activité annuel HT', category: 'sign_in', order: 1,
      },
      {
        _id: 'signAccessory', type: 'currency', label: 'Loyer accessoire annuel HT', category: 'sign_in', commentary: true, optional: true, order: 2,
      },
      {
        _id: 'signRentRate', type: 'rate', label: 'Loyer variable', category: 'sign_in', optional: true, order: 3,
      },
      {
        _id: 'signIn', type: 'currency', label: "Droit d'entrée", category: 'sign_in', optional: true, order: 4,
      },
      {
        _id: 'signCharges', type: 'currency', label: 'Charges annuelles HT', category: 'sign_charge', commentary: true, order: 5,
      },
      {
        _id: 'signTaxes', type: 'currency', label: 'Taxes annuelles HT', category: 'sign_charge', commentary: true, order: 6,
      },
      {
        _id: 'signWarranty', type: 'currency', label: 'Dépôt de garantie à la signature', category: 'sign_warranty', order: 7,
      },
      {
        _id: 'keepWarranty', type: 'list', label: 'Gestion du dépôt de garantie', category: 'sign_warranty', order: 8,
      },
      {
        _id: 'initWarranty',
        type: 'currency',
        label: 'Autres garanties',
        category: 'sign_warranty',
        commentary: true,
        commentaryPlaceHolder: 'exemples : Caution personnelle, Garantie autonome à première demande',
        order: 9,
      },
      {
        _id: 'rentWorks', type: 'currency', label: 'Participation aux travaux', category: 'sign_work', order: 10,
      },
      {
        _id: 'rentFree', type: 'currency', label: 'Franchise de loyer', category: 'sign_work', commentary: true, order: 11,
      },
      /* taxe */
      {
        _id: 'rent', type: 'currency', label: 'Loyer bureau année HT', category: 'taxe', required: true, history: true, order: 0,
      },
      {
        _id: 'activity', type: 'currency', label: 'Loyer activité année HT', category: 'taxe', history: true, order: 1,
      },
      {
        _id: 'accessory', type: 'currency', label: 'Loyer accessoire année HT', category: 'taxe', history: true, optional: true, order: 2,
      },
      {
        _id: 'charges', type: 'currency', label: 'Charges annéé HT', category: 'taxe', history: true, order: 3,
      },
      {
        _id: 'taxes', type: 'currency', label: 'Taxes année HT', category: 'taxe', history: true, order: 4,
      },
      {
        _id: 'warranty', type: 'currency', label: 'Dépôt de garantie année', category: 'taxe', history: true, order: 5,
      },
      /* indexation */
      {
        _id: 'indexation', type: 'list', label: 'Période', category: 'indexation', order: 0,
      },
      {
        _id: 'indexIndexation', type: 'list', label: "Indice d'indexation", category: 'indexation', order: 1,
      },
      {
        _id: 'quarterIndexation', type: 'list', label: 'Trimestre de référence', category: 'indexation', order: 2,
      },
      {
        _id: 'referenceIndex', type: 'number', label: "Valeur de l'indice de référence", category: 'indexation', order: 3,
      },
      /* commentary */
      {
        _id: 'particularClauses', type: 'commentary', label: 'Clause(s) particulière(s)', category: 'commentary', commentary: true, order: 0,
      },
      /* detail */
      {
        _id: 'totalArea', type: 'surface', label: 'Surface totale', category: 'detail', calculate: true, order: 4,
      },
    ],
    juridiqueFields: [
      /* work */
      {
        _id: 'work606',
        type: 'list',
        label: 'Travaux 606',
        tooltip: 'L’article 606 du Code civil vise les grosses réparations sur le clos et le couvert. Elles incombent légalement au bailleur.',
        category: 'work',
        order: 0,
      },
      {
        _id: 'workDisrepair', type: 'list', label: 'Travaux de vétusté', category: 'work', order: 1,
      },
      {
        _id: 'workConforme', type: 'list', label: 'Travaux de mise en conformité', category: 'work', order: 2,
      },
      {
        _id: 'workMaintenance', type: 'list', label: "Travaux d'entretien", category: 'work', order: 3,
      },
      {
        _id: 'workRenovation', type: 'list', label: "Travaux de rénovation, d'amélioration", category: 'work', order: 4,
      },
      {
        _id: 'workDetail', type: 'commentary', label: 'Commentaire(s)', category: 'work', commentary: true, order: 5,
      },
      /* lessor */
      {
        _id: 'restitution',
        type: 'string',
        label: 'Etat de restitution',
        // eslint-disable-next-line max-len
        tooltip: 'Une clause du bail indique dans quel état vous devez restituer les locaux lors de votre départ : bon état, très bon état, parfait état...',
        category: 'lessor',
        order: 5,
      },
      {
        _id: 'restoration',
        type: 'string',
        label: 'Remise en état primitif',
        // eslint-disable-next-line max-len
        tooltip: 'Le bail peut prévoir une remise en état primitif des locaux, c’est à dire que vous les restituez dans le même état que celui de l’état des lieux d’entrée.<br>Si vous avez réalisé des travaux d’aménagement, le bailleur pourra vous demander de les déposer.',
        category: 'lessor',
        order: 6,
      },
      {
        _id: 'otherWorks', type: 'commentary', label: 'Commentaires', category: 'lessor', commentary: true, order: 7,
      },
      /* other */
      {
        _id: 'otherConditions', type: 'commentary', label: 'Détail(s)', category: 'other', commentary: true, order: 0,
      },
    ],
  },
  mutations: {
    SET_LEASES(state, leases) {
      state.leases = leases;
    },
    ADD_LEASE(state, lease) {
      state.leases = [...state.leases, lease];
    },
    PATCH_LEASE(state, lease) {
      state.leases = [...state.leases.filter((_lease) => _lease._id !== lease._id), lease];
    },
    DELETE_LEASE(state, leaseId) {
      state.leases = state.leases.filter((_lease) => _lease._id !== leaseId);
    },
  },
  actions: {
    async getLeases(context) {
      if (authService.hasPermission('LEASES_READ')) {
        try {
          const response = await leaseService.getLeases();
          context.commit('SET_LEASES', response);
        } catch (error) {
          context.commit('SET_LEASES', []);
          context.commit('ADD_ERROR', VueI18n.t('Errors.Leases.get'));
          // throw error;
        }
      }
    },
  },
};
