import Axios from 'axios';
import axiosRetry from 'axios-retry';

class ApiClient {
  constructor({ config, authService }) {
    this.axios = Axios.create({
      baseURL: config.baseUrl,
      timeout: config.timeout || 5000,
      headers: { 'Content-Type': 'application/json' },
    });
    this.authService = authService;
    this.setupAuthorizationToken();
    this.setupHandleError();

    axiosRetry(this.axios, {
      retries: 3,
      retryDelay: (retryCount) => retryCount * 2000,
      retryCondition: () => true,
    });
  }

  setupAuthorizationToken() {
    this.axios.interceptors.request.use((config) => {
      const token = this.authService.tokenStorageService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, (error) => {
      Promise.reject(error);
    });
  }

  setupHandleError() {
    this.axios.interceptors.response.use((response) => response, (error) => {
      const originalRequest = error.config;
      const user = this.authService.getUserFromToken();
      const refreshToken = this.authService.tokenStorageService.getRefresh();
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return this.authService.token({
          username: user.username,
          refreshToken,
        }).then((response) => {
          if (response.status === 200) {
            return this.axios(originalRequest);
          }
          return null;
        }).catch(() => {
          this.authService.logout();
          document.location.reload();
          return null;
        });
      }
      return Promise.reject(error);
    });
  }

  async sendRequest(request) {
    // eslint-disable-next-line no-useless-catch
    try {
      return await this.axios.request(request);
    } catch (error) {
      throw error;
    }
  }
}

export default ApiClient;
