class UserService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getUsers() {
    const request = {
      method: 'GET',
      url: '/iam/users',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async deleteUsers(username) {
    const request = {
      method: 'DELETE',
      url: `/iam/users/${username}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async postUsers(newUser) {
    const request = {
      method: 'POST',
      url: '/iam/users',
      data: newUser,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async patchUsers(username, user) {
    const request = {
      method: 'PATCH',
      url: `/iam/users/${username}`,
      data: user,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }
}

export default UserService;
