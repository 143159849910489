import { authService } from '../services';

export default {
  state: {
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    job: '',
  },
  mutations: {
    SET_USER(state, user) {
      state.username = user.username;
      state.firstname = user.firstname;
      state.lastname = user.lastname;
      state.email = user.email;
      state.job = user.job;
    },
    SET_FIRSTNAME(state, firstname) {
      state.firstname = firstname;
    },
    SET_LASTNAME(state, lastname) {
      state.lastname = lastname;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_JOB(state, job) {
      state.job = job;
    },
  },
  actions: {
    getUser(context) {
      context.commit('SET_USER', authService.getUserFromToken());
    },
  },
};
