export default {
  env: {
    domain: process.env.VUE_APP_DOMAIN || 'localhost'
  },
  api: {
    backend: {
      baseUrl: process.env.VUE_APP_BACKEND_BASE_URL || '/.netlify/functions',
      timeout: 5000,
    },
    upload: {
      timeout: 25000,
    },
    insee: {
      timeout: 25000,
      token: process.env.VUE_APP_INSEE_TOKEN || '',
    },
  },
  sentry: {
    dsn: process.env.VUE_APP_SENTRY_DSN
  },
  analytics: {
    trakingId: process.env.VUE_APP_GOOGLE_ANALYTICS_TRAKING_ID,
  },
};
