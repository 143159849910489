import Axios from 'axios';

class UploadService {
  constructor({ config }) {
    this.axios = Axios.create({
      timeout: config.timeout || 10000,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async postFile(url, formData) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.axios({
        url,
        method: 'PUT',
        data: formData,
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default UploadService;
