class EntityService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getEntities() {
    const request = {
      method: 'GET',
      url: '/api/entities',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async postEntities(entity) {
    const request = {
      method: 'POST',
      url: '/api/entities',
      data: entity,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async deleteEntities(entityId) {
    const request = {
      method: 'DELETE',
      url: `/api/entities/${entityId}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async patchEntities(entityId, entity) {
    const request = {
      method: 'PATCH',
      url: `/api/entities/${entityId}`,
      data: entity,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }
}

export default EntityService;
