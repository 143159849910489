import VueI18n from '../plugins/vue-i18n';

import { scopeService, authService } from '../services';

export default {
  state: {
    scopes: [],
  },
  mutations: {
    SET_SCOPES(state, scopes) {
      state.scopes = scopes;
    },
    ADD_SCOPE(state, scope) {
      state.scopes = [...state.scopes, scope];
    },
    PATCH_SCOPE(state, scope) {
      state.scopes = [...state.scopes.filter((_scope) => _scope._id !== scope._id), scope];
    },
    DELETE_SCOPE(state, scopeId) {
      state.scopes = state.scopes.filter((_scope) => _scope._id !== scopeId);
    },
  },
  actions: {
    async getScopes(context) {
      if (authService.hasPermission('SCOPES_READ')) {
        try {
          const response = await scopeService.getScopes();
          context.commit('SET_SCOPES', response);
        } catch (error) {
          context.commit('ADD_ERROR', VueI18n.t('Errors.Scopes.get'));
          // throw error;
        }
      }
    },
  },
};
