export default {
  state: {
    errors: [],
    warnings: [],
    success: [],
    infos: [],
  },
  mutations: {
    ADD_ERROR(state, error) {
      state.errors.push(error);
    },
    ADD_WARNING(state, warning) {
      state.warnings.push(warning);
    },
    ADD_SUCCESS(state, success) {
      state.success.push(success);
    },
    ADD_INFO(state, info) {
      state.infos.push(info);
    },
    CLEAR_ALERTS(state, type) {
      switch (type) {
        case 'error':
          state.errors = [];
          break;
        case 'warning':
          state.warnings = [];
          break;
        case 'success':
          state.success = [];
          break;
        case 'info':
          state.infos = [];
          break;
        default:
          state.errors = [];
          state.warnings = [];
          state.success = [];
          state.infos = [];
      }
    },
  },
};
