import VueI18n from '../plugins/vue-i18n';

import { userService, authService } from '../services';

export default {
  state: {
    users: [],
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    ADD_USER(state, user) {
      state.users = [...state.users, user];
    },
    PATCH_USER(state, user) {
      state.users = [...state.users.filter((_user) => _user.username !== user.username), user];
    },
    DELETE_USER(state, username) {
      state.users = state.users.filter((_user) => _user.username !== username);
    },
  },
  actions: {
    async getUsers(context) {
      if (authService.hasPermission('USERS_READ')) {
        try {
          const response = await userService.getUsers();
          context.commit('SET_USERS', response);
        } catch (error) {
          context.commit('ADD_ERROR', VueI18n.t('Errors.Users.get'));
          // throw error;
        }
      }
    },
  },
};
