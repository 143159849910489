import Vue from 'vue';
import VueRouter from 'vue-router';

import $store from '../store';
import { authService } from '../services';

const Main = () => import('../views/layout/main/Main.vue');

const Login = () => import('../views/pages/login/Login.vue');
const PasswordForgot = () => import('../views/pages/login/PasswordForgot.vue');
const Dashboard = () => import('../views/pages/dashboard/Dashboard.vue');
const Leases = () => import('../views/pages/leases/Leases.vue');
const Lease = () => import('../views/pages/leases/Lease.vue');
const Users = () => import('../views/pages/settings/Users.vue');
const Roles = () => import('../views/pages/settings/Roles.vue');
const Entities = () => import('../views/pages/settings/List/Entities.vue');
const Scopes = () => import('../views/pages/settings/List/Scopes.vue');

const NotFound = () => import('../views/pages/error/NotFound.vue');
const Unauthorized = () => import('../views/pages/error/Unauthorized.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: Main,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          permission: 'LEASES_READ',
        },
      },
      {
        path: '/leases',
        name: 'leases',
        component: Leases,
        meta: {
          permission: 'LEASES_READ',
        },
      },
      {
        path: '/leases/:id',
        name: 'lease',
        component: Lease,
        meta: {
          permission: 'LEASES_READ',
        },
      },
      {
        path: '/settings/users',
        name: 'users',
        component: Users,
        meta: {
          permission: 'USERS_READ',
        },
      },
      {
        path: '/settings/roles',
        name: 'roles',
        component: Roles,
        meta: {
          permission: 'ROLES_READ',
        },
      },
      {
        path: '/settings/entities',
        name: 'entities',
        component: Entities,
        meta: {
          permission: 'ENTITIES_READ',
        },
      },
      {
        path: '/settings/scopes',
        name: 'scopes',
        component: Scopes,
        meta: {
          permission: 'SCOPES_READ',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/passwordForgot',
    name: 'passwordForgot',
    component: PasswordForgot,
  },
  { path: '/401', name: '401', component: Unauthorized },
  { path: '*', name: '404', component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.isAuthorized = (nextRoute) => {
  // Control permission
  const neededPermissions = nextRoute.matched.reduce((permissions, subRoute) => (subRoute.meta.permission
    ? [...permissions, subRoute.meta.permission]
    : permissions), []);

  const userPermissions = authService.getPermissionFromToken();

  return neededPermissions.length === 0
  || userPermissions.includes('ADMIN')
  || neededPermissions.every((neededPermission) => userPermissions.includes(neededPermission));
};

router.beforeEach(async (to, from, next) => {
  // Error page
  if (['404', '401', 'login', 'passwordForgot'].includes(to.name)) {
    return next();
  }

  // Check user connected
  if (!authService.isConnected()) {
    // If not connected redirect to login page
    return next({ name: 'login' });
  }

  // If app is not initialize, init it
  if (!$store.state.userModule.username) {
    $store.dispatch('initialize');
  }

  // Check if user can access the next page
  if (!router.isAuthorized(to)) {
    return next({ name: '401' });
  }

  // Go to the next page
  return next();
});

export default router;
