class LeaseService {
  constructor({ apiClient, uploadService }) {
    this.apiClient = apiClient;
    this.uploadService = uploadService;
  }

  async getLeases(params) {
    const request = {
      params,
      method: 'GET',
      url: '/api/leases',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async patchLease(id, data) {
    const request = {
      data,
      method: 'PATCH',
      url: `/api/leases/${id}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async postLease(data) {
    const request = {
      data,
      method: 'POST',
      url: '/api/leases',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async deleteLease(leaseId) {
    const request = {
      method: 'DELETE',
      url: `/api/leases/${leaseId}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async uploadFile(leaseId, documentId, file) {
    // Get urlSigned
    const requestUrlSigned = {
      method: 'POST',
      url: '/api/documents',
      data: {
        leaseId,
        documentId,
        extension: file.name.split('.').pop(),
      },
    };

    const responseUrlSigned = await this.apiClient.sendRequest(requestUrlSigned);

    if (responseUrlSigned.status === 200 && responseUrlSigned.data) {
      // Post File
      const url = responseUrlSigned.data.uploadPath;
      await this.uploadService.postFile(url, file);
    }

    return responseUrlSigned.data;
  }

  async readFile(path) {
    const request = {
      method: 'GET',
      url: `/api/documents/${path}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }
}

export default LeaseService;
