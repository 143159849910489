import VueI18n from '../plugins/vue-i18n';

import { entityService, authService } from '../services';

export default {
  state: {
    entities: [],
  },
  mutations: {
    SET_ENTITIES(state, entities) {
      state.entities = entities;
    },
    ADD_ENTITY(state, entity) {
      state.entities = [...state.entities, entity];
    },
    PATCH_ENTITY(state, entity) {
      state.entities = [...state.entities.filter((_entity) => _entity._id !== entity._id), entity];
    },
    DELETE_ENTITY(state, entityId) {
      state.entities = state.entities.filter((_entity) => _entity._id !== entityId);
    },
  },
  actions: {
    async getEntities(context) {
      if (authService.hasPermission('ENTITIES_READ')) {
        try {
          const response = await entityService.getEntities();
          context.commit('SET_ENTITIES', response);
        } catch (error) {
          context.commit('ADD_ERROR', VueI18n.t('Errors.Entities.get'));
          // throw error;
        }
      }
    },
  },
};
