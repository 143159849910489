export default {
  state: {
    boolean: [
      { _id: true, label: 'Oui' },
      { _id: false, label: 'Non' },
    ],
    period: [
      { _id: 'm', label: 'Moi(s)' },
      { _id: 'y', label: 'Année(s)' },
      { _id: 'd', label: 'Jour(s)' },
    ],
    status: [
      { _id: 'ENABLED', label: 'Actif' },
      { _id: 'DISABLED', label: 'Inactif' },
    ],
    indexation: [
      { _id: 'YEAR', label: 'Annuelle' },
      { _id: 'THREE_YEAR', label: 'Triennale' },
    ],
    indexIndexation: [
      { _id: 'ICC', label: 'Indice Coût Construction (ICC)', idBank: '000008630' },
      { _id: 'ILAT', label: 'Indice Loyers Activités Tertiaires (ILAT)', idBank: '001617112' },
      { _id: 'ILC', label: 'Indice Loyers Commerciaux (ILC)', idBank: '001532540' },
    ],
    quarterIndexation: [
      { _id: '1', label: '1er trimestre' },
      { _id: '2', label: '2ème trimestre' },
      { _id: '3', label: '3ème trimestre' },
      { _id: '4', label: '4ème trimestre' },
    ],
    leaseType: [
      { _id: 'COMMERCIAL', label: 'Bail commercial' },
      { _id: 'PRO', label: 'Bail professionnel' },
      { _id: 'CIVIL', label: 'Bail civil' },
      { _id: 'OVER', label: 'Bail dérogatoire' },
      { _id: 'OVER_SUBLEASE', label: 'Bail de sous-location dérogatoire' },
      { _id: 'CONTRACT', label: 'Contrat de mise à disposition' },
      { _id: 'CONVENTION', label: "Convention d'occupation précaire" },
      { _id: 'LESSOR', label: 'Propriétaire occupant' },
      { _id: 'SUBLEASE', label: 'Sous-location' },
    ],
    keepWarranty: [
      { _id: 'KEEP', label: 'Conservé par le bailleur' },
      { _id: 'DONE', label: 'Restitué' },
    ],
    documentCategories: [
      { _id: 'lease', label: 'Bail et avenant(s)', order: 0 },
      { _id: 'etat', label: 'États des lieux', order: 1 },
      { _id: 'annexe', label: 'Annexe(s)', order: 2 },
      { _id: 'plan', label: 'Plan(s)', order: 3 },
      { _id: 'bill', label: 'Facture(s)', order: 4 },
      { _id: 'contrat', label: 'Contrat(s)', order: 5 },
      { _id: 'exchange', label: 'Echange(s) bailleur', order: 6 },
      { _id: 'leave', label: 'Congé(s)', order: 7 },
      { _id: 'other', label: 'Diver(s)', order: 8 },
    ],
    work606: [
      { _id: 'FOR_TENOR', label: 'à la charge du preneur' },
      { _id: 'FOR_RENTER', label: 'à la charge du bailleur' },
    ],
    workDisrepair: [
      { _id: 'FOR_TENOR', label: 'à la charge du preneur' },
      { _id: 'FOR_RENTER', label: 'à la charge du bailleur' },
    ],
    workConforme: [
      { _id: 'FOR_TENOR', label: 'à la charge du preneur' },
      { _id: 'FOR_RENTER', label: 'à la charge du bailleur' },
    ],
    workMaintenance: [
      { _id: 'FOR_TENOR', label: 'à la charge du preneur' },
      { _id: 'FOR_RENTER', label: 'à la charge du bailleur' },
    ],
    workRenovation: [
      { _id: 'FOR_TENOR', label: 'à la charge du preneur' },
      { _id: 'FOR_RENTER', label: 'à la charge du bailleur' },
    ],
    permissions: [
      'ADMIN',
      /* ENTITIES */
      'ENTITIES_CREATE',
      'ENTITIES_READ',
      'ENTITIES_UPDATE',
      'ENTITIES_DELETE',
      /* LEASES */
      'LEASES_CREATE',
      'LEASES_READ',
      'LEASES_UPDATE',
      'LEASES_DELETE',
      /* DOCUMENTS */
      'DOCUMENTS_CREATE',
      'DOCUMENTS_READ',
      /* SCOPES */
      'SCOPES_CREATE',
      'SCOPES_READ',
      'SCOPES_UPDATE',
      'SCOPES_DELETE',
      /* USERS */
      'USERS_CREATE',
      'USERS_READ',
      'USERS_UPDATE',
      'USERS_DELETE',
      /* ROLES */
      'ROLES_CREATE',
      'ROLES_READ',
      'ROLES_UPDATE',
      'ROLES_DELETE',
    ],
  },
};
