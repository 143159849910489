class RoleService {
  constructor({ apiClient }) {
    this.apiClient = apiClient;
  }

  async getRoles() {
    const request = {
      method: 'GET',
      url: '/iam/roles',
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async postRoles(role) {
    const request = {
      method: 'POST',
      url: '/iam/roles',
      data: role,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async deleteRoles(roleId) {
    const request = {
      method: 'DELETE',
      url: `/iam/roles/${roleId}`,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }

  async patchRoles(roleId, role) {
    const request = {
      method: 'PATCH',
      url: `/iam/roles/${roleId}`,
      data: role,
    };

    const response = await this.apiClient.sendRequest(request);
    return response.data;
  }
}

export default RoleService;
